import React from 'react'


function Page(props) {
  
  if(typeof window !== 'undefined') {
    window.location.replace('https://www.teamworkonline.com:443/live-event-jobs/1st-jobs/1st-jobs?')
  }

  return (
    <div>
      .
    </div>
  )
}

export default Page